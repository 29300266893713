import React, { ReactElement } from "react";
import { Project } from "./models/project";
import { Worker } from "./models/worker";

type PageHeaderContextProps = {
  project?: Project;
  setProject?: React.Dispatch<React.SetStateAction<Project | undefined>>;
  worker?: Worker;
  setWorker?: React.Dispatch<React.SetStateAction<Worker | undefined>>;
  button?: ReactElement;
  setButton?: React.Dispatch<React.SetStateAction<ReactElement | undefined>>;
};

export const PageHeaderContext = React.createContext<PageHeaderContextProps>(
  {}
);
