import { SidebarItemsType, SidebarSectionType } from "../../types/sidebar";

import {
  Sliders,
  Users,
  Table,
  User,
  Mail,
  XCircle,
  CheckCircle,
  MapPin,
  Package,
  Briefcase,
  Book,
  BookOpen,
  CheckSquare,
  Paperclip,
  Codepen,
  Compass,
  Framer,
  Phone,
} from "react-feather";
import { Tune } from "@mui/icons-material";
import VisiblyAdminNavItemGuard from "./navItemGuards/VisiblyAdminNavItemGuard";
import CanViewChallengeSetPerformanceNavItemGuard from "../../features/skills/components/guards/CanViewChallengeSetPerformanceNavItemGuard";
import CanViewPracticalReviewsNavItemGuard from "../../features/learning/components/guards/CanViewPracticalReviewsNavItemGuard";
import CanViewCapabilityNavItemGuard from "./navItemGuards/CanViewNavItemGuard";

const checkInsSection = [
  {
    href: "/check-ins/dashboard",
    icon: CheckCircle,
    title: () => "Dashboard",
  },
  {
    href: "/check-ins/checks",
    icon: MapPin,
    title: () => "Check-ins",
  },
  {
    href: "/check-ins/analytics",
    icon: Table,
    title: () => "Analytics",
    children: [
      {
        href: "/check-ins/analytics/user-activity",
        icon: Table,
        title: () => "User Activity",
      },
    ],
  },
] as SidebarItemsType[];

const learningAdministrationSection = [
  {
    href: "/learning-administration/practical-reviews",
    icon: CheckSquare,
    title: () => "Practical reviews",
  },
] as SidebarItemsType[];

const learningSection = [
  {
    href: "/learning/analytics",
    icon: Table,
    title: () => "Analytics",
    children: [
      {
        href: "/learning/analytics/course-summary",
        icon: Book,
        title: () => "Course summary",
      },
    ],
  },
  {
    href: "/learning/enrolments",
    icon: Table,
    title: () => "Enrolments",
    children: [
      {
        href: "/learning/enrolments/active",
        icon: BookOpen,
        title: () => "Active enrolments",
      },
      {
        href: "/learning/enrolments/completed",
        icon: CheckCircle,
        title: () => "Completed enrolments",
      },
    ],
  },
] as SidebarItemsType[];

const internalAdminSection = [
  {
    href: "/internal-admin/upload-invites",
    icon: Users,
    title: () => "Bulk invite users",
  },
  {
    href: "/internal-admin/upload-activity-types",
    icon: Package,
    title: () => "Import Activity Types",
  },
  {
    href: "/internal-admin/import-challenge-set",
    icon: Tune,
    title: () => "Import Challenge Set",
  },
  {
    href: "/internal-admin/create-organisation",
    icon: Briefcase,
    title: () => "Create Organisation",
  },
] as SidebarItemsType[];

const accreditationsSection = [
  {
    href: "/accreditations/specifications",
    icon: Paperclip,
    title: () => "Accreditation specs.",
  },
  {
    href: "/accreditations/roles",
    icon: Book,
    title: () => "Roles",
  },
  {
    href: "/accreditations/accreditations-list",
    icon: Codepen,
    title: () => "Assigned accreds.",
  },
  {
    href: "/accreditations/compliance",
    icon: Table,
    title: () => "Compliance",
    children: [
      {
        href: "/accreditations/compliance/roles",
        icon: Framer,
        title: () => "Current compliance",
      },
      {
        href: "/accreditations/compliance/accreditations",
        icon: Compass,
        title: () => "Explore",
      },
    ],
  },
] as SidebarItemsType[];

const navSections = [
  {
    title: "Certification",
    pages: learningSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="learning">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Certification (Administration)",
    pages: learningAdministrationSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewPracticalReviewsNavItemGuard key={key}>
          {children}
        </CanViewPracticalReviewsNavItemGuard>
      );
    },
  },
  {
    title: "Accreditations",
    pages: accreditationsSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="accreditations">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Duty of care",
    pages: checkInsSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="checkIns">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Internal admin",
    pages: internalAdminSection,
    wrapperElement: (key, children) => {
      return (
        <VisiblyAdminNavItemGuard key={key}>
          {children}
        </VisiblyAdminNavItemGuard>
      );
    },
  },
] as SidebarSectionType[];

export default navSections;
