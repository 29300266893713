import CanViewCapabilityNavItemGuard from "../components/sidebar/navItemGuards/CanViewNavItemGuard";
import VisiblyAdminNavItemGuard from "../components/sidebar/navItemGuards/VisiblyAdminNavItemGuard";
import { SidebarItemsType, SidebarSectionType } from "../types/sidebar";
import { Users, Briefcase, CheckCircle } from "react-feather";

const complianceSection = [
  {
    href: "/compliance/direct-workforce",
    icon: Users,
    title: () => "Direct workforce",
    innerNavigationItems: [
      {
        title: "Dashboard",
        to: (_) => "/compliance/direct-workforce/dashboard",
        disabled: true,
      },
      {
        title: "Project roles",
        to: (_) => "/compliance/direct-workforce/project-roles",
        disabled: true,
      },
      {
        title: "Workers",
        to: (_) => "/compliance/direct-workforce/workers",
      },
      {
        title: "Worker roles",
        to: (_) => "/compliance/direct-workforce/worker-roles",
        disabled: true,
      },
      {
        title: "Accreditations",
        to: (_) => "/compliance/direct-workforce/accreditations",
      },
    ],
    children: [
      {
        title: (workerName: string) => `${workerName}`,
        hasBackButton: true,
        backButtonTo: (_) => `/compliance/direct-workforce/workers`,
        type: "worker",
        innerNavigationItems: [
          {
            title: "Details",
            to: (_) =>
              `/compliance/direct-workforce/workers/${_.workerId}/details`,
          },
          {
            title: "Worker roles",
            to: (_) =>
              `/compliance/direct-workforce/workers/${_.workerId}/worker-roles`,
          },
          {
            title: "Accreditations",
            to: (_) =>
              `/compliance/direct-workforce/workers/${_.workerId}/accreditations`,
          },
        ],
      },
    ],
  },
] as SidebarItemsType[];

const internalAdminSection = [
  {
    href: "/internal-admin/create-organisation",
    icon: Briefcase,
    title: () => "Create Organisation",
  },
] as SidebarItemsType[];

const checkInsSection = [
  {
    href: "/check-ins",
    icon: CheckCircle,
    title: () => "Duty of care",
    innerNavigationItems: [
      {
        title: "Dashboard",
        to: (_) => "/check-ins/dashboard",
      },
      {
        title: "Check-ins",
        to: (_) => "/check-ins/checks",
      },
      {
        title: "Analytics",
        to: (_) => "/check-ins/analytics/user-activity",
      },
    ],
  },
] as SidebarItemsType[];

const navSections = [
  {
    title: "Compliance",
    pages: complianceSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="accreditations">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Duty of care",
    pages: checkInsSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="checkIns">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Internal admin",
    pages: internalAdminSection,
    wrapperElement: (key, children) => {
      return (
        <VisiblyAdminNavItemGuard key={key}>
          {children}
        </VisiblyAdminNavItemGuard>
      );
    },
  },
] as SidebarSectionType[];

export default navSections;
