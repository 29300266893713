import { Button, Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InnerNavigationItem } from "../../types/sidebar";

type InnerNavigationBarProps = {
  items?: InnerNavigationItem[];
};

export function InnerNavigationBar(props: InnerNavigationBarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, workerId } = useParams();

  return (
    <Grid container spacing={6} ml={0} mt={2}>
      <Grid item xs={12} mb={10}>
        {props.items?.map((el, index) => {
          const url = el.to({
            projectId: projectId,
            workerId: workerId,
          });
          return (
            <Button
              variant={location.pathname === url ? "contained" : "outlined"}
              style={{ marginRight: 10 }}
              onClick={() => {
                navigate(url);
              }}
              disabled={el.disabled}
              key={index}
            >
              {el.title}
            </Button>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default InnerNavigationBar;
