import {
  Button,
  Card as MuiCard,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { getTeamMembers } from "../../slices/teamMembersSlice";
import { getCurrentOrgId } from "../../../../contexts/AmplifyContext";
import useAppSelector from "../../../../hooks/useAppSelector";
import { RootState } from "../../../../redux/store";
import TeamMembersModal from "./TeamMembersModal";
import queryString from "query-string";
import { EditTeamQueryParams } from "../../models/teams";
import { useSnackbar } from "notistack";
import { LoadingStatus } from "../../../../models/loadingStatus";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
} from "ag-grid-community";
import { OrganisationMember } from "../../../../models/organisationMember";
import AgGridTable from "../../../../components/AgGridTable";
import { removeTeamMembers } from "../../services/teamsService";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";

const Card = styled(MuiCard)(spacing);
const LoadingButton = styled(MuiLoadingButton)(spacing);

type BasicInfoTabProps = {
  teamId: string;
  tabValue: number;
};

function TeamMembersTab({ teamId, tabValue }: BasicInfoTabProps) {
  const dispatch = useAppDispatch();
  const currentOrgId = getCurrentOrgId();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const [removeMembersDisabledFlag, setRemoveMembersDisabledFlag] =
    useState(true);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const location = useLocation();
  const { defaultTab } = queryString.parse(
    location.search
  ) as EditTeamQueryParams;
  const [showEditMembersModal, setShowEditMembersModal] = useState(
    (defaultTab && defaultTab === "team-members") ?? false
  );

  const teamMembers = useAppSelector(
    (state: RootState) => state.teamMembers.teamMembers
  );
  const teamMembersStatus = useAppSelector(
    (state: RootState) => state.teamMembers.status
  );

  useEffect(() => {
    if (teamId && currentOrgId) {
      dispatch(
        getTeamMembers({
          orgId: currentOrgId,
          teamId: teamId,
        })
      );
    }
  }, [currentOrgId, teamId, dispatch]);

  const columns: ColDef[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      flex: 0.25,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      cellRenderer: (
        props: ICellRendererParams<OrganisationMember, string>
      ) => {
        return (
          <Link
            component={NavLink}
            to={`/users/${props.data?.userId}`}
            className="visiblyLink"
          >
            {props.value}
          </Link>
        );
      },
    },
    {
      field: "jobTitle",
      headerName: "Job title",
      sortable: false,
      flex: 1,
    },
    {
      field: "inOrgEmail",
      headerName: "Invite email",
      sortable: false,
      flex: 1,
    },
  ];

  const onRemoveMembersHandler = async () => {
    if (!selectedIds.length) {
      return;
    }
    setIsUpdating(true);
    const existingUserIds = teamMembers.map((teamMember) => teamMember.userId);

    const userIdsToKeep = existingUserIds.filter(
      (id) => selectedIds.indexOf(id) === -1
    );

    setSelectedIds([]);
    setRemoveMembersDisabledFlag(true);

    const userIdsToRemove = existingUserIds.filter(
      (userId) => userIdsToKeep.indexOf(userId) === -1
    );

    if (userIdsToRemove.length) {
      await removeTeamMembers({
        teamId: teamId,
        userIds: userIdsToRemove,
      });
    }

    await dispatch(
      getTeamMembers({
        orgId: currentOrgId!,
        teamId: teamId,
      })
    );

    setIsUpdating(false);

    const snackbarKey = enqueueSnackbar(`Team members removed!`, {
      variant: "success",
      onClick: () => closeSnackbar(snackbarKey),
    });
  };

  const data = useMemo(() => {
    return teamMembers.map((element) => {
      return {
        ...element,
        name: `${element.firstNames} ${element.lastName}`,
      };
    });
  }, [teamMembers]);

  return (
    <>
      {tabValue === 1 ? (
        <>
          <TeamMembersModal
            initialSelectedUserIds={teamMembers.map((e) => e.userId)}
            teamId={teamId!}
            show={showEditMembersModal}
            onClose={() => {
              setShowEditMembersModal(false);
            }}
          />
          <Card mt={4} mb={6}>
            {teamMembersStatus !== LoadingStatus.succeeded ? (
              <Grid container m={20}>
                <Grid item xs={12}>
                  <LoadingStatusIndicator
                    loadingStatuses={[teamMembersStatus]}
                  />
                </Grid>
              </Grid>
            ) : (
              <Paper>
                <div style={{ height: "60vh", width: "100%" }}>
                  <AgGridTable
                    dataCyTag="teamMembersTable"
                    columns={columns}
                    data={data}
                    noRowsOverlay={() => (
                      <Grid container>
                        <Grid
                          item
                          xs
                          textAlign="center"
                          justifyContent="center"
                        >
                          <Typography variant="h6">
                            This team currently has no members
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    gridOptions={{
                      rowSelection: "multiple",
                      onRowSelected: (
                        event: RowSelectedEvent<OrganisationMember, any>
                      ) => {
                        let ids = event.api
                          .getSelectedRows()
                          .map((element) => element.userId);
                        setSelectedIds(ids);
                        setRemoveMembersDisabledFlag(ids.length === 0);
                      },
                    }}
                    search={{ enabled: true }}
                    toolbarElements={[
                      <LoadingButton
                        variant="contained"
                        disabled={removeMembersDisabledFlag}
                        onClick={onRemoveMembersHandler}
                        data-cy="removeMembersButton"
                        loading={isUpdating}
                      >
                        Remove members
                      </LoadingButton>,
                      <Button
                        variant="contained"
                        onClick={() => {
                          setShowEditMembersModal(true);
                        }}
                        data-cy="editMembersButton"
                      >
                        Edit members
                      </Button>,
                    ]}
                  />
                </div>
              </Paper>
            )}
          </Card>
        </>
      ) : null}
    </>
  );
}

export default TeamMembersTab;
