import { lazy } from "react";

import DashboardLayout from "./layouts/Dashboard";
import LogoFormLayout from "./layouts/LogoForm";
import BasicLayout from "./layouts/Basic";

import SignIn from "./features/account/pages/signIn";
import ForgotPassword from "./features/account/pages/resetPassword/ForgotPassword";
import EditTeam from "./features/teams/pages/editTeam";

import { Navigate, RouteObject } from "react-router-dom";
import CanUseTemplateLibraryGuard from "./features/skills/components/guards/CanUseTemplateLibraryGuard";
import VisiblyAdminGuard from "./components/guards/VisiblyAdminGuard";
import CanViewChallengeSetPerformanceNavItemGuard from "./features/skills/components/guards/CanViewChallengeSetPerformanceGuard";

import { UserOrganisationRole } from "./features/user/models/user";
import UserGuard from "./components/guards/UserGuard";
import GuestGuard from "./components/guards/GuestGuard";
import EditEventSubscription from "./features/teams/pages/editTeam/EditEventSubscription";
import InternalCreateOrganisation from "./features/users/pages/CreateOrganisation";
import AuthGuard from "./components/guards/AuthGuard";
import NavigateToUserHome from "./components/NavigateToUserHome";
import CanViewPracticalReviewsRouteGuard from "./features/learning/components/guards/CanViewPracticalReviewsRouteGuard";
import CanViewCapabilityRouteGuard from "./components/guards/CanViewCapabilityRouteGuard";
import CompletedEnrolments from "./features/learning/pages/enrolments/completedEnrolments";
import VisiblyExternalPartnerGuard from "./components/guards/VisiblyExternalGuard";
import VisiblyLearningDesignerGuard from "./components/guards/VisiblyLearningDesignerGuard";
import VisiblyOSLayout from "./visiblyOS/VisiblyOSLayout";

const Eula = lazy(() => import("./features/account/pages/eula"));
const Register = lazy(() => import("./features/account/pages/register"));
const RegisterSuccess = lazy(
  () => import("./features/user/pages/RegisterSuccess")
);
const AcceptInvite = lazy(() => import("./features/user/pages/AcceptInvite"));
const ChooseOrganisation = lazy(
  () => import("./features/organisation/pages/chooseOrganisation")
);
const ChallengeSetSummary = lazy(
  () => import("./features/skills/pages/analytics/challengeSetSummary")
);
const ManageTeams = lazy(() => import("./features/teams/pages/ManageTeams"));
const CreateOrganisation = lazy(
  () => import("./features/organisation/pages/CreateOrganisation")
);
const RegisterConfirm = lazy(
  () => import("./features/account/pages/register/RegisterConfirm")
);
const ResetPassword = lazy(
  () => import("./features/account/pages/resetPassword")
);
const CheckEmail = lazy(
  () => import("./features/account/pages/resetPassword/CheckEmail")
);
const ActiveUsers = lazy(() => import("./features/users/pages/ActiveUsers"));
const InvitedUsers = lazy(() => import("./features/users/pages/InvitedUsers"));
const DeactivatedUsers = lazy(
  () => import("./features/users/pages/DeactivatedUsers")
);
const Dashboard = lazy(() => import("./features/skills/pages/dashboard"));
const UserProfile = lazy(() => import("./features/user/pages/userProfile"));
const CreateInvite = lazy(() => import("./features/users/pages/CreateInvite"));

const ChallengeLibrary = lazy(
  () => import("./features/skills/pages/challengesLibrary")
);
const ChallengeSets = lazy(
  () => import("./features/skills/pages/challengeSets")
);
const EditChallengeSets = lazy(
  () => import("./features/skills/pages/challengeSets/edit/EditChallengeSets")
);
const ReviewerActivity = lazy(
  () => import("./features/skills/pages/analytics/reviewerActivity")
);
const DownloadApp = lazy(() => import("./features/account/pages/downloadApp"));
const ChallengeInstanceDetails = lazy(
  () => import("./features/skills/pages/challengeInstanceDetails")
);
const ChallengeSetComponentSummary = lazy(
  () => import("./features/skills/pages/analytics/challengeSetComponentSummary")
);
const ChallengerActivity = lazy(
  () => import("./features/skills/pages/analytics/challengerActivity")
);
const OutstandingChallengeList = lazy(
  () => import("./features/skills/pages/OutstandingChallengeList")
);
const ChallengeSetPerformance = lazy(
  () => import("./features/skills/pages/analytics/challengeSetPerformance")
);
const ChallengeInstances = lazy(
  () => import("./features/skills/pages/challengeInstances")
);
const ReviewerDiscrepancies = lazy(
  () =>
    import("./features/skills/pages/analytics/hierarchicalReviewDiscrepancies")
);
const CreateTeam = lazy(() => import("./features/teams/pages/createTeam"));
const CheckIns = lazy(() => import("./features/checkIns/pages/checkins"));
const CheckInsDashboard = lazy(
  () => import("./features/checkIns/pages/dashboard")
);
const CheckInsUserActivity = lazy(
  () => import("./features/checkIns/pages/analytics")
);
const UserActivityInstancesList = lazy(
  () => import("./features/checkIns/pages/analytics/UserActivityInstancesList")
);
const UserActivityDetails = lazy(
  () => import("./features/checkIns/pages/analytics/UserActivityDetails")
);
const CourseSummary = lazy(
  () => import("./features/learning/pages/analytics/courseSummary")
);
const CourseUnitSummary = lazy(
  () => import("./features/learning/pages/analytics/courseUnitSummary")
);
const CourseEnrolments = lazy(
  () => import("./features/learning/pages/analytics/enrolmentsForCourse")
);
const PracticalReviews = lazy(
  () => import("./features/learning/pages/practicalReviews")
);
const ReviewPracticalChallenge = lazy(
  () =>
    import(
      "./features/learning/pages/practicalReviews/ReviewPracticalChallenge"
    )
);
const ActiveEnrolments = lazy(
  () => import("./features/learning/pages/enrolments/activeEnrolments")
);
const BulkEnrol = lazy(
  () => import("./features/learning/pages/enrolments/bulkEnrol")
);
const EnrolmentDetails = lazy(
  () => import("./features/learning/pages/enrolments/enrolmentDetails")
);

const DeleteAccount = lazy(
  () => import("./features/account/pages/deleteAccount")
);

const VideoUpload = lazy(() => import("./features/external/pages/videoUpload"));
const Transcribe = lazy(() => import("./features/external/pages/transcribe"));

const AccreditationRoles = lazy(
  () => import("./features/accreditations/pages/roles")
);

const AccreditationRolePage = lazy(
  () => import("./features/accreditations/pages/roles/AccreditationRolePage")
);

const EditAccreditationSpec = lazy(
  () => import("./features/accreditations/pages/accreditationSpecs/edit")
);

const AccreditationSpecs = lazy(
  () => import("./features/accreditations/pages/accreditationSpecs")
);

const RoleCompliancePage = lazy(
  () => import("./features/accreditations/pages/compliance/roleCompliance")
);

const AccreditationsCompliancePage = lazy(
  () =>
    import("./features/accreditations/pages/compliance/accreditationCompliance")
);

const CreateUser = lazy(() => import("./features/users/pages/CreateUser"));

const CommunicationGroupList = lazy(
  () => import("./features/communications/pages")
);

const EditCommunicationGroup = lazy(
  () => import("./features/communications/pages/editGroup")
);

const DirectWorkforceAccreditations = lazy(
  () => import("./visiblyOS/features/directWorkforce/pages/Accreditations")
);

const DirectWorkforceWorkers = lazy(
  () => import("./visiblyOS/features/directWorkforce/pages/Workers")
);

const unauthenticatedRoutes = [
  {
    path: "/",
    element: <NavigateToUserHome />,
  },
  {
    children: [
      {
        element: <BasicLayout />,
        children: [
          {
            path: "delete-account",
            element: <DeleteAccount />,
          },
          {
            path: "register-success",
            element: <RegisterSuccess />,
          },
          {
            path: "accept-invite/:id",
            element: <AcceptInvite />,
          },
        ],
      },
      {
        element: (
          <GuestGuard>
            <BasicLayout />
          </GuestGuard>
        ),
        children: [
          {
            path: "auth",
            children: [
              {
                path: "sign-in",
                element: <SignIn />,
              },
              {
                path: "forgot-password",
                element: <ForgotPassword />,
              },
              {
                path: "check-email",
                element: <CheckEmail />,
              },
            ],
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
        ],
      },
      {
        element: <BasicLayout />,
        children: [
          {
            path: "register/:id",
            element: <Register />,
          },
          {
            path: "register-confirm",
            element: <RegisterConfirm />,
          },
        ],
      },
    ],
  },
] as RouteObject[];

const skillsRoutes = [
  {
    path: "skills",
    element: (
      <CanViewCapabilityRouteGuard capability="skills">
        <UserGuard
          allowedRoles={[
            UserOrganisationRole.owner,
            UserOrganisationRole.admin,
          ]}
        >
          <DashboardLayout />,
        </UserGuard>
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "analytics",
        children: [
          {
            path: "challenge-set-summary",
            element: <ChallengeSetSummary />,
          },
          {
            path: "challenge-set-summary/:id",
            element: <ChallengeSetComponentSummary />,
          },
          {
            path: "challenger-activity",
            element: <ChallengerActivity />,
          },
          {
            path: "reviewer-activity",
            element: <ReviewerActivity />,
          },
          {
            path: "review-discrepancies",
            element: <ReviewerDiscrepancies />,
          },
          {
            path: "challenge-set-performance",
            element: (
              <CanViewChallengeSetPerformanceNavItemGuard>
                <ChallengeSetPerformance />
              </CanViewChallengeSetPerformanceNavItemGuard>
            ),
          },
        ],
      },
      {
        path: "challenges",
        children: [
          {
            path: "challenge-sets",
            element: <ChallengeSets />,
          },
          {
            path: "challenge-sets/edit/:id",
            element: <EditChallengeSets />,
          },
          {
            path: "library",
            element: (
              <CanUseTemplateLibraryGuard>
                <ChallengeLibrary />
              </CanUseTemplateLibraryGuard>
            ),
          },
          {
            path: "outstanding",
            element: <OutstandingChallengeList />,
          },
          {
            path: "instances",
            element: <ChallengeInstances />,
          },
          {
            path: "instance-details/:id",
            element: <ChallengeInstanceDetails />,
          },
        ],
      },
    ],
  },
] as RouteObject[];

const learningAdministrationRoutes = [
  {
    path: "learning-administration",
    element: (
      <CanViewPracticalReviewsRouteGuard>
        <DashboardLayout />
      </CanViewPracticalReviewsRouteGuard>
    ),
    children: [
      {
        path: "practical-reviews",
        element: <PracticalReviews />,
      },
      {
        path: "review-practical-challenge/:id",
        element: <ReviewPracticalChallenge />,
      },
    ],
  },
];

const learningRoutes = [
  {
    path: "learning",
    element: (
      <CanViewCapabilityRouteGuard capability="learning">
        <DashboardLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "",
        element: <NavigateToUserHome />,
      },
      {
        path: "analytics",
        children: [
          {
            path: "course-summary",
            element: <CourseSummary />,
          },
          {
            path: "course-unit-summary",
            element: <CourseUnitSummary />,
          },
          {
            path: "course-enrolments",
            element: <CourseEnrolments />,
          },
        ],
      },
      {
        path: "enrolments",
        children: [
          {
            path: "active",
            element: <ActiveEnrolments />,
          },
          {
            path: "completed",
            element: <CompletedEnrolments />,
          },
          {
            path: "bulk-enrol",
            element: <BulkEnrol />,
          },
          {
            path: "details/:id",
            element: <EnrolmentDetails />,
          },
        ],
      },
    ],
  },
] as RouteObject[];

const checkInsRoutes = [
  {
    path: "check-ins",
    element: (
      <CanViewCapabilityRouteGuard capability="checkIns">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/check-ins/dashboard" />,
      },
      {
        path: "dashboard",
        element: <CheckInsDashboard />,
      },
      {
        path: "checks",
        element: <CheckIns />,
      },
      {
        path: "analytics",
        element: <Navigate to="/check-ins/analytics/user-activity" />,
      },
      {
        path: "analytics/user-activity",
        element: <CheckInsUserActivity />,
      },
      {
        path: "analytics/user-activity-instances/:id",
        element: <UserActivityInstancesList />,
      },
      {
        path: "analytics/user-activity-details/:id",
        element: <UserActivityDetails />,
      },
    ],
  },
] as RouteObject[];

const teamsRoutes = [
  {
    path: "teams",
    element: (
      <UserGuard
        allowedRoles={[UserOrganisationRole.owner, UserOrganisationRole.admin]}
      >
        <DashboardLayout />,
      </UserGuard>
    ),
    children: [
      {
        path: "manage",
        element: <ManageTeams />,
      },
      {
        path: "edit/:id",
        element: <EditTeam />,
      },
      {
        path: "create",
        element: <CreateTeam />,
      },
      {
        path: ":teamId/event-subscription/create",
        element: <EditEventSubscription type="create" />,
      },
      {
        path: ":teamId/event-subscription/edit/:id",
        element: <EditEventSubscription type="update" />,
      },
    ],
  },
] as RouteObject[];

const usersRoutes = [
  {
    path: "users",
    element: (
      <UserGuard
        allowedRoles={[UserOrganisationRole.owner, UserOrganisationRole.admin]}
      >
        <DashboardLayout />,
      </UserGuard>
    ),
    children: [
      {
        path: "active",
        element: <ActiveUsers />,
      },
      {
        path: "invited",
        element: <InvitedUsers />,
      },
      {
        path: "deactivated",
        element: <DeactivatedUsers />,
      },
      {
        path: "invite",
        element: <CreateInvite />,
      },
      {
        path: ":id",
        element: <UserProfile />,
      },
      {
        path: "create",
        element: <CreateUser />,
      },
    ],
  },
] as RouteObject[];

const organisationRoutes = [
  {
    path: "organisation",
    element: <LogoFormLayout />,
    children: [
      {
        path: "create",
        element: <CreateOrganisation />,
      },
      {
        path: "choose",
        element: <ChooseOrganisation />,
      },
    ],
  },
] as RouteObject[];

const internalAdminRoutes = [
  {
    path: "internal-admin",
    element: (
      <VisiblyAdminGuard>
        <VisiblyOSLayout />
      </VisiblyAdminGuard>
    ),
    children: [
      // {
      //   path: "upload-invites",
      //   element: <BulkUploadInvites />,
      // },
      // {
      //   path: "upload-activity-types",
      //   element: <BulkUploadActivityTypes />,
      // },
      // {
      //   path: "import-challenge-set",
      //   element: <ImportChallengeSet />,
      // },
      {
        path: "create-organisation",
        element: <InternalCreateOrganisation />,
      },
    ],
  },
] as RouteObject[];

const externalRoutes = [
  {
    path: "external",
    element: <BasicLayout />,
    children: [
      {
        path: "video-upload",
        element: (
          <VisiblyExternalPartnerGuard>
            <VideoUpload />
          </VisiblyExternalPartnerGuard>
        ),
      },
      {
        path: "transcribe",
        element: (
          <VisiblyLearningDesignerGuard>
            <Transcribe />
          </VisiblyLearningDesignerGuard>
        ),
      },
    ],
  },
] as RouteObject[];

const accreditationRoutes = [
  {
    path: "accreditations",
    element: (
      <CanViewCapabilityRouteGuard capability="accreditations">
        <DashboardLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "specifications",
        element: <AccreditationSpecs />,
      },
      {
        path: "specifications/:id/update",
        element: <EditAccreditationSpec type="update" />,
      },
      {
        path: "specifications/create",
        element: <EditAccreditationSpec type="create" />,
      },
      {
        path: "roles",
        element: <AccreditationRoles />,
      },
      {
        path: "roles/create",
        element: <AccreditationRolePage type="create" />,
      },
      {
        path: "roles/edit/:id",
        element: <AccreditationRolePage type="update" />,
      },
      {
        path: "compliance",
        children: [
          {
            path: "roles",
            element: <RoleCompliancePage />,
          },
          {
            path: "accreditations",
            element: <AccreditationsCompliancePage />,
          },
        ],
      },
    ],
  },
] as RouteObject[];

const communicationsRoutes = [
  {
    path: "communications",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <CommunicationGroupList />,
      },
      {
        path: ":id/edit",
        element: <EditCommunicationGroup type="update" />,
      },
      {
        path: "create",
        element: <EditCommunicationGroup type="create" />,
      },
    ],
  },
] as RouteObject[];

const directWorkforceRoutes = [
  {
    path: "compliance",
    element: <VisiblyOSLayout />,
    children: [
      {
        path: "direct-workforce",
        element: <Navigate to="/compliance/direct-workforce/workers" />,
      },
      {
        path: "direct-workforce/accreditations",
        element: <DirectWorkforceAccreditations />,
      },
      {
        path: "direct-workforce/workers",
        element: <DirectWorkforceWorkers />,
      },
    ],
  },
] as RouteObject[];

const routes = [
  ...unauthenticatedRoutes,
  ...organisationRoutes,
  ...checkInsRoutes,
  ...internalAdminRoutes,
  ...externalRoutes,
  ...communicationsRoutes,
  ...directWorkforceRoutes,
  {
    path: "download-app",
    element: (
      <BasicLayout>
        <DownloadApp />
      </BasicLayout>
    ),
  },
  {
    path: "eula",
    element: (
      <AuthGuard>
        <BasicLayout>
          <Eula />
        </BasicLayout>
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <NavigateToUserHome />,
  },
] as RouteObject[];

export type { RouteObject };

export default routes;
