import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { uploadFileToFileStorage } from "../../../../services/s3ClientStorageService";
import { importUsers } from "../services/accreditationUserService";
import { LoadingStatus } from "../../../../models/loadingStatus";
import { AccreditationUser } from "../models/accreditationUser";

type ImportUserCsvRequest = {
  orgId: string;
  file: File;
};

export const importUsersCsv = createAsyncThunk(
  "accreditationUser/importUsersCsv",
  async (request: ImportUserCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );

      return await importUsers({
        orgId: request.orgId,
        csvImportId: importId,
      });
    } catch (err) {
      console.warn(err);
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type AccreditationUserState = {
  status: LoadingStatus;
  bulkImportResponse: AccreditationUser[];
  error: string | null;
};

const initialState: AccreditationUserState = {
  status: LoadingStatus.idle,
  bulkImportResponse: [],
  error: null,
};

const accreditationUserSlice = createSlice({
  name: "accreditationUser",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importUsersCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importUsersCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        bulkImportResponse: action.payload.data.data,
      };
    });
    builder.addCase(importUsersCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { resetState } = accreditationUserSlice.actions;

export default accreditationUserSlice.reducer;
