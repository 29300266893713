import styled from "@emotion/styled";
import { useEffect } from "react";
import { useCurrentOrg } from "../../../user/hooks/userHooks";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Enrolment, EnrolmentState } from "../../models/enrolment";
import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import { LoadingStatus } from "../../../../models/loadingStatus";
import useAppSelector from "../../../../hooks/useAppSelector";
import { queryEnrolments } from "../../slices/learningEnrolmentSlice";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import AgGridTable from "../../../../components/AgGridTable";
import { useNavigate } from "react-router-dom";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

function CompletedEnrolments() {
  const currentOrg = useCurrentOrg();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const enrolmentStatus = useAppSelector(
    (state) => state.learningEnrolment.status
  );

  const enrolments = useAppSelector(
    (state) => state.learningEnrolment.enrolments
  );

  useEffect(() => {
    if (currentOrg?.id) {
      dispatch(
        queryEnrolments({
          orgId: currentOrg?.id,
          states: [EnrolmentState.complete],
        })
      );
    }
  }, [currentOrg, dispatch]);

  let columns: ColDef[] = [
    {
      field: "name",
      headerName: "Course name",
      headerTooltip: "Course name",
      sortable: true,
      suppressColumnsToolPanel: true,
      flex: 1.5,
      cellRenderer: (props: ICellRendererParams<Enrolment, string>) => {
        if (props.value) {
          return (
            <Tooltip title={props.value}>
              <p style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {props.value}
              </p>
            </Tooltip>
          );
        }
        return "";
      },
    },
    {
      field: "userFirstNames",
      headerName: "User's name",
      headerTooltip: "User's name",
      sortable: true,
      enableRowGroup: false,
      flex: 1.5,
      cellRenderer: (props: ICellRendererParams<Enrolment, string>) => {
        if (props.value) {
          const value = `${props.data?.userFirstNames} ${props.data?.userLastName}`;
          return (
            <Tooltip title={value}>
              <p style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {value}
              </p>
            </Tooltip>
          );
        }
        return "";
      },
    },
    {
      field: "userEmail",
      headerName: "User's email",
      headerTooltip: "User's email",
      sortable: true,
      flex: 1.5,
      cellRenderer: (props: ICellRendererParams<Enrolment, string>) => {
        if (props.value) {
          return (
            <Tooltip title={props.value}>
              <p style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {props.value}
              </p>
            </Tooltip>
          );
        }
        return "";
      },
    },
    {
      field: "completedAt",
      headerName: "Completed at",
      headerTooltip: "Completed at",
      sortable: true,
      suppressMenu: true,
      flex: 1,
      cellRenderer: (props: ICellRendererParams<string>) => {
        if (props.value !== undefined) {
          return format(new Date(props.value), "dd/MM/yyyy HH:mm");
        }
        return "-";
      },
    },
    {
      field: "scorePercentage",
      headerName: "Score",
      headerTooltip: "Score",
      suppressMenu: true,
      sortable: true,
      flex: 0.5,
      cellRenderer: (props: ICellRendererParams<number>) => {
        if (props.value !== undefined) {
          return `${props.value}%`;
        }
        return "-";
      },
    },
    {
      headerName: "Assessment requested",
      headerTooltip: "Assessment requested",
      sortable: true,
      suppressMenu: true,
      flex: 1,
      cellRenderer: (props: ICellRendererParams<Enrolment>) => {
        if (!props.data) {
          return "";
        }
        if (props.data.assessment.requestedAt) {
          return format(
            new Date(props.data.assessment.requestedAt),
            "dd/MM/yyyy HH:MM"
          );
        }
        return "-";
      },
    },
  ];

  if (enrolmentStatus !== LoadingStatus.succeeded) {
    return <LoadingStatusIndicator loadingStatuses={[enrolmentStatus]} />;
  }

  return (
    <>
      <Helmet
        title={`Completed enrolments`}
        data-cy="completedEnrolmentsPage"
      />
      <Grid container item justifyContent={"space-between"}>
        <Grid item xs={12} sm={6} md={9}>
          <Typography
            variant="h3"
            gutterBottom
            display="inline"
            data-cy="completedEnrolmentsHeader"
          >
            Completed enrolments
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Certification</Typography>
            <Typography>Enrolments</Typography>
            <Typography>Completed enrolments</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box style={{ height: "70vh", minHeight: "690px" }}>
            <Card>
              <CardContent>
                <Grid
                  container
                  height={"70vh"}
                  minHeight={"690px"}
                  width={"100%"}
                >
                  <Grid item xs={12}>
                    <AgGridTable
                      data={enrolments}
                      columns={columns}
                      dataCyTag="completeEnrolmentsTable"
                      enableGrouping
                      enableSideBar
                      paginationAutoPageSize={false}
                      paginationPageSize={10}
                      export={{ fileTag: "completed_enrolments" }}
                      menu={{
                        menuItems: (row) => [
                          {
                            label: "Details",
                            onClick: () => {
                              navigate(
                                `/learning/enrolments/details/${row.id}`
                              );
                            },
                            testingClassName: `enrolmentDetails${row.id}`,
                          },
                        ],
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default CompletedEnrolments;
