import { AxiosPromise } from "axios";
import axios from "../../../../utils/axios";
import { ApiResponseMeta } from "../../../../models/apiResponseMeta";
import { AccreditationUser } from "../models/accreditationUser";

type BulkImportRequest = {
  csvImportId: string;
  orgId: string;
};

type BulkImportResponse = {
  meta: ApiResponseMeta;
  data: AccreditationUser[];
};

export async function importUsers(
  request: BulkImportRequest
): Promise<AxiosPromise<BulkImportResponse>> {
  return await axios.post(`accreditations/users/v1/import-from-csv`, request);
}
