import { AxiosPromise } from "axios";
import axios from "../../../../utils/axios";
import { ApiResponseMeta } from "../../../../models/apiResponseMeta";
import { QueryAccreditationsRequest } from "../slices/accreditationSlice";
import { Accreditation } from "../models/accreditation";

type BulkImportRequest = {
  csvImportId: string;
  orgId: string;
};

type BulkImportResponse = {
  meta: ApiResponseMeta;
};

export async function importAccreditations(
  request: BulkImportRequest
): Promise<AxiosPromise<BulkImportResponse>> {
  return await axios.post(`accreditations/v1/import-from-csv`, request);
}

type QueryAccreditationsResponse = {
  meta: ApiResponseMeta;
  data: Accreditation[];
};

export async function queryAccreditations(
  request: QueryAccreditationsRequest
): Promise<AxiosPromise<QueryAccreditationsResponse>> {
  return await axios.post(`accreditations/v1/query`, request);
}
